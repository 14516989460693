import { checkScoreOps, checkScoreMap } from './map'
import { uploadURL } from 'common/api'
import { createImgVNode } from 'common/vdom'

const getScoreDetailHeader = function (displayModel) {
    let arr = []
    if (displayModel == 2) {
        arr.push({ 
            prop: 'checkScore',
            width: 100,
            label: '检查得分',
            formatter: (row, prop) => {
                return checkScoreMap[row[prop]] || ''
            }
        })
    }else{
        arr.push({
            prop: 'maxScore',
            width: 50,
            label: '检查分值',
        })
        arr.push({ 
            prop: 'checkMark',
            width: 100,
            label: '检查得分'
        })
    }
    return arr
}

const scoreValidator = function (row) {
    return function (rule, value, callback) {
        const { checkMark, maxScore } = row;
        if (['', undefined, null].includes(checkMark)) {
            callback(new Error('请输入得分'))
        }else if (!(+checkMark >= 0 && +checkMark <= +maxScore)) {
            callback(new Error('请输入正确得分'))
        } else {
            callback()
        }
    }
}
const getScoreEditHeader = function (displayModel) {
    let arr = []
    if (displayModel == 2) {
        arr.push({ 
            prop: 'checkScore',
            label: '检查得分',
            formatter: (row, prop) => {
                return this.$createElement('checkbox-plus', {
                    props: {
                        value: row[prop] + '',
                        type: 'radio',
                        options: checkScoreOps()
                    },
                    on: {
                        'update:value' (newValue) {
                            row[prop] = newValue
                        },
                    }
                })
            }
        })
    }else{
        arr.push({
            prop: 'maxScore',
            width: 50,
            label: '检查分值',
        })
        arr.push({ 
            prop: 'checkMark',
            label: '检查得分',
            formatter: (row, prop) => {
                return this.$createElement('el-form-item', {
                    props: {
                        prop: prop,
                        rules: [
                            {
                                validator: scoreValidator(row),
                                trigger: 'blur'
                            },
                        ]
                    },
                    style: {
                        margin: 0
                    }
                }, 
                [
                    this.$createElement('v-input', {
                    props: {
                        value: row[prop],
                        width: 'auto'
                    },
                    on: {
                        input (newValue, el) {
                            let val = clearNoNum(newValue)
                            row[prop] = Number.parseFloat(val)
                            el.changeModel(val)
                        },
                    }
                    })
                ]
                )
            }
        })
    }
    return arr
}
const getRectifyHeader = function (isEdit, isRectify) {
    let arr = []
    if(isRectify > 1) {
        if(isRectify  == 2) {
            arr.push({
                prop: 'rectificationContent',
                label: '整改内容',
                width: 150,
                formatter: (row, prop) => {
                    if((row.displayModel === 1 && isRectification(row.checkMark, row.maxScore)) || (row.displayModel === 2 && row.checkScore == '0')) {
    
                        return this.$createElement('v-textarea', {
                            props: {
                                value: row[prop],
                                placeholder: "请输入备注信息",
                                width: 'auto',
                            },
                            on: {
                                input (newValue) {
                                    row[prop] = newValue
                                },
                            }
                        })
                    } else {
                        // 
                        return this.$createElement('div', {
                            domProps: {
                                innerText: row[prop]
                            },
                            style: {
                                width: `100%`,
                                whiteSpace: 'normal',
                            }
                        })
                    }
                    
                }
            })
            arr.push({
                prop: 'correctivePhotos',
                label: '整改照片',
                width: 170,
                formatter: (row, prop) => {
                    let imgUrls = [];
                    let imgUrlSytr = row[prop];
    
                    if(imgUrlSytr === '') {
                        imgUrls = [];
                    } else if (imgUrlSytr.indexOf(',') !== -1) {
                        imgUrls = imgUrlSytr.split(',');
                    } else {
                        imgUrls.push(imgUrlSytr);
                    }
                    return this.$createElement('v-uploader', {
                        props: {
                            imgUrls: imgUrls,
                            fileWidth: 63,
                            limit: 4,
                            action: `${uploadURL}?module=user`,
                        },
                        on: {
                            'update:imgUrls': (data) => {
                                let _imgUrls = [],
                                    _imgUrlStr = '';
                                data.forEach(item => {
                                    if(item && item !== '') {
                                        _imgUrls.push(item)
                                    }
                                })
    
                                if(_imgUrls.length > 1) {
                                    _imgUrlStr = _imgUrls.join()
                                } else if( _imgUrls.length === 1) {
                                    _imgUrlStr = `${_imgUrls[0]}`
                                }
                                row[prop] = _imgUrlStr;
                            }
                        }
                    })
                }
            })
        } else {
            arr.push({
                prop: 'rectificationContent',
                label: '整改内容',
            })
            arr.push({
                prop: 'correctivePhotos',
                label: '整改照片',
                width: 170,
                formatter: (row, prop) => {
                    let nodeList = [];
                    row[prop] && row[prop].split(',').forEach(imgUrl => {
                        nodeList.push(createImgVNode(this, row, prop, {
                            imgUrl,
                            style: {
                                marginRight: '2px',
                                marginBottom: '2px'
                            }
                        }))
                    })
                    return this.$createElement('div', {
                        style: {
                            'display': 'flex',
                            'flex-wrap': 'wrap',
                        }
                    }, nodeList)
                }
            })
        }
    }
    return arr
}
const getScoreHeader = function(isEdit, displayModel) {
    return isEdit ? getScoreEditHeader.call(this, displayModel) : getScoreDetailHeader.call(this, displayModel)
}

const clearNoNum = function clearNoNum(item)
{
    //先把非数字的都替换掉，除了数字和.
    item = `${item}`.replace(/[^\d.]/g,"");
    //保证只有出现一个.而没有多个.
    item = item.replace(/\.{2,}/g,".");
    //只能输入两个小数
    item = item.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');
    return item
}

// 判断是否需要整改方法
const isRectification = function(score, maxScore) {
    return score < maxScore? true : false
}

export {
    getRectifyHeader,
    getScoreHeader,
    clearNoNum
}